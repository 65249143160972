import React from 'react'
import Navbar from './global-components/navbar'
import PageHead from "./global-components/PageHead";
import Footer from "./global-components/footer";
import { Link, useHistory } from "react-router-dom";
import CallToActionV1 from "./section-components/call-to-action-v1";
import CallToActionSco from './section-components/call-to-action-sco';


const RealEstate = () => {
      let publicUrl = process.env.PUBLIC_URL+'/'
      const history = useHistory()
  
  return (
    <>
  <Navbar/>
  <PageHead headertitle="Welcome To Way2LandMark" subheader="amaravati-real-estate"/>


  <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">

        {/* --------------------------header-content----------------- */}
        <div className="Real-head">
             <h1> Your Trusted Partner for Amaravati Real Estate Properties</h1>
             <p>Are you searching for the perfect property in Amaravati? Whether you’re interested in buying your dream home or investing in the growing real estate market, Way2Landmark offers unparalleled expertise and a wide range of Amaravati real estate properties to meet your needs. We are proud to be one of the leading real estate dealers in Amaravati, specializing in residential and commercial properties that offer both exceptional value and excellent returns on investment.
             </p>
        </div>

        {/* -------------------------------image&content----------------- */}

          <div className="Real-row">
            <div className="Real-img-col">
              <div className="Real-img">
                <img
                  src={publicUrl + "assets/img/service/Services.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="real-content">
              <div className="Real-info">
                <div className="real">
                <h2 className="Real-title">
                  Discover Amaravati:A Thriving Real Estate Market<span >.</span>
                </h2>
                <p>
                  Amaravati, the capital city of Andhra Pradesh, has become a prime destination for real estate investors and homebuyers. The city’s rapid urbanization, robust infrastructure, and development of government-backed projects have made it one of the most attractive locations for real estate investments in India. As more people flock to the city for work, education, and business opportunities, the demand for residential and commercial properties continues to rise.


                </p>
                </div>
                <div className="real-about">
                  <p >
                  At Way2Landmark, we provide you with exclusive access to the most sought-after <h3 class="inline">properties for sale in Amaravati</h3> , ensuring that you have the best opportunities to invest in this growing market. Whether you’re looking for a quiet suburban home or a bustling commercial space, we have a wide array of options that suit every preference and budget
                  </p>
                </div>
      
              </div>
            </div>
          </div>


          <div className="Real-row--reverse">
            <div className="Real-img-col">
              <div className="Real-img">
                <img
                  src={publicUrl + "assets/img/service/0001.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="real-content">
              <div className="Real-info">
                <div className="real">
                  <h2 className="Real-title">
                  Expert Guidance for Home Buyers and Sellers in Amaravati<span>.</span>
                  </h2>
                  <p >
                  Navigating the world of <h3 class="inline">home buying and selling dealers in Amaravati</h3> can be overwhelming, especially with the increasing number of properties hitting the market. At Way2Landmark, we make the process simple, straightforward, and transparent. Our team of experienced real estate professionals is here to guide you through every step of your property journey.
                  </p>
                </div>
                <div className="real-about">
                  <p>
                  We understand that purchasing or selling a property is one of the most significant financial decisions you’ll ever make. That’s why we provide in-depth market insights, clear advice, and professional services to help you make informed decisions. Whether you’re a first-time homebuyer or a seasoned investor, our team will ensure you get the best deal possible.
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="Real-row">
            <div className="Real-img-col">
              <div className="Real-img">
                <img
                  src={publicUrl + "assets/img/others/aboutway.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="real-content">
              <Link></Link>
              <div className="Real-info">
                <div className="real">
                  <h2 className="Real-title">
                  The Best Properties for Sale in Amaravati<span>.</span>
                  </h2>
                  <p>
                  When it comes to <h3 class="inline">properties for sale in Amaravati</h3>, the options are as diverse as the needs of our clients. At Way2Landmark, we focus on delivering a broad range of properties that suit various lifestyles, from affordable homes to luxurious estates. Our listings include:
                  </p>
                </div>
                <div className="real-about">
                  <ul className='heading'>
                <li>
                    <span>Residential Properties:</span> From cozy apartments to spacious villas, we offer a variety of residential options for families, young professionals, and retirees. Our homes are located in prime areas across Amaravati, ensuring convenience and connectivity.
                 </li>
                 <li>
                       <span class="">Commercial Spaces:</span> Amaravati’s expanding business hub offers excellent opportunities for investors looking for commercial real estate. Whether you are looking for retail space, office buildings, or mixed-use properties, we provide competitive listings to meet your business needs.
                     </li>
                  <li>
                    <span class="">Plots and Land:</span> For those interested in land investment, we have access to well-located plots that promise high returns in the coming years as the city continues to grow and develop.
                  </li>
                  </ul>

                  <p>Our team works tirelessly to curate properties that not only meet the current demands but also offer future value appreciation. We believe in offering high-quality <h3 class="inline"> Amaravati real estate properties</h3>  that provide long-term value.</p>
                </div>
              </div>
            </div>
          </div>

    <div class="section-abc">
      <div class="header-way2LandMark">
    <h2>Why Choose <span class="highlight">Way2Landmark? </span></h2>
    </div>

    <div class="text-image-container">
      <div class="text-block">
        <div class="text-item">
         
          <h3 class="heading">1. In-depth Knowledge of the Amaravati Market</h3>
          <p>Way2Landmark has been deeply involved in the Amaravati real estate market for several years, and our team possesses comprehensive knowledge of local trends, regulations, and pricing. We keep a close eye on the ever-changing market conditions, ensuring that you have up-to-date information at your disposal to make informed decisions.</p>
        </div>

        <div class="text-item">
          <h3 class="heading">2. Expert Advice and Personalized Service</h3>
          <p>When you work with us, you’re not just another client – you’re a valued partner. We understand that every client has different needs, which is why we provide personalized advice to help you find the right <h3 class="inline"> property for sale in Amaravati</h3>. Whether you are a first-time homebuyer or an experienced investor, we listen to your requirements and recommend the best options that match your vision.
          </p>
        </div>

        <div class="text-item">
          <h3 class="heading">3.A Seamless Experience for Home Buying and Selling
          </h3>
          <p>Selling a property or purchasing one can often be stressful. However, when you choose Way2Landmark as your <h3 class="inline">home buying and selling dealers in Amaravati</h3>, we make sure the entire process is smooth, transparent, and hassle-free. From paperwork to negotiations, we handle all the details, ensuring that your experience is seamless from start to finish.
          </p>
        </div>

        <div class="text-item">
     <h3 class="heading">4.A Wide Range of Properties for Every Budget
          </h3>
          <p>We cater to all kinds of clients with varying budgets. Whether you’re looking for a budget-friendly property or a luxury villa, we have listings across all price ranges. Our focus is to help you get the best value for your money without compromising on quality or location.
         </p>
        </div>



        <div class="text-item">
          <h3 class="heading">5.Strong Network and Connections
          </h3>
          <p>With a strong network of developers, investors, and real estate professionals, Way2Landmark has access to exclusive listings and off-market deals that you won’t find anywhere else. This gives you an edge in a competitive market, ensuring you get the best possible options.
     </p>
        </div>

        
        <div class="text-item">
          <h2 class="heading"> Amaravati Real Estate: A Smart Investment for the Future
          
          </h2>
          <p>Investing in  <h3 class="inline">Amaravati real estate properties </h3>is not just about purchasing a home; it’s about securing your future. With the city’s constant development, infrastructure upgrades, and growing population, Amaravati offers great potential for long-term returns on investment.
          </p>
          <p>Whether you’re purchasing a residential property to live in or buying commercial space for investment purposes, the real estate market in Amaravati promises substantial growth. Way2Landmark offers expert property valuation services, helping you make decisions based on market data and trends, so you can rest assured that your investment will pay off in the years to come.
          </p>
        </div>    
        <div class="text-item">
          <h2 class="heading">Let Way2Landmark Help You Find Your Dream Property
          
          </h2>
          <p>Finding the right <h3 class="inline">property for sale in Amaravati </h3> can be challenging without the right guidance. That’s where Way2Landmark comes in. Our team of experts is committed to making your real estate journey as smooth as possible, ensuring that you find a property that fits your needs, lifestyle, and budget.

          </p>
          <p>Whether you're looking to buy, sell, or invest, we are here to offer professional advice and facilitate the best real estate transactions. Our extensive portfolio of<h3 class="inline"> Amaravati real estate properties</h3> ensures that you have a variety of options at your fingertips.

          </p>
          
        </div>      
      </div>


    <div class="image-block">
      <div class="slider-wrapper">
        <div class="slider-track">
          <img src="assets/img/service/0003.jpg" alt="none" />
          <img src="assets/img/others/01.jpg" alt="none" />
        </div>
      </div>

           <button class="image-button"   onClick={() => history.push('/service')}>Explore Properties  <i className="icon-next" /></button>
       </div>
    </div>
  </div>   
        </div>
      </div>
      <CallToActionSco/>
  
      <Footer />
  </>
  )
}

export default RealEstate