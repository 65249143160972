import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Instence from '../../utils/Instence';

const PageHead = ({ headertitle, subheader, search, location, sublocation, customclass, Img }) => {
    const HeaderTitle = headertitle;
    const publicUrl = process.env.PUBLIC_URL + '/';
    const Subheader = subheader ? subheader : HeaderTitle;
    const Filtered = search ? search : null;
    const citylocation = location ? location : null;
    const citysublocation = sublocation ? sublocation : null;
    const CustomClass = customclass ? customclass : '';
    const img = Img ? Img : '14.jpg';

	const [cityName, setCityName] = useState("");

const getCityName = async() => {
	try {
		const response = await Instence.get(`Location/${citylocation}`);
		if(response.status === 200){
			console.log(response.data.name);
			setCityName(response.data.name);
		}
	} catch (error) {
		console.log(error)
	}
}

useEffect(() => {
    getCityName();
}, [citylocation]);


    return (
        <div className={`ltn__breadcrumb-are text-left bg-overlay-white-30 bg-image ${CustomClass}`} data-bs-bg={`${publicUrl}assets/img/bg/${img}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__breadcrumb-inner">
                            <h1 className="page-title">{headertitle}</h1>
                            <div className="ltn__breadcrumb-list">
                                <ul>
                                    <li><Link to="/"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                                    <li>{subheader}</li>
                                    <li>{Filtered}</li>
                                    {cityName && <li>{cityName}</li>}
                                    {citysublocation && <li>{citysublocation}</li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Removed the extra dot */}
                </div>
            </div>
        </div>
    );
}

export default PageHead;
