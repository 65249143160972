import React from 'react';
import { Link } from 'react-router-dom';

const CallToActionSco = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
              <div className="coll-to-info text-color-white ">
                <h1>Looking for best Properties</h1>
                <p>Contact Way2Landmark today to explore the finest properties for sale in Amaravati <br/>and take the first step toward securing your future.</p>
              </div>
              <div className="btn-wrapper go-top" onClick={scrollToTop}>
                <Link className="btn btn-effect-3 btn-white" to="/contact">
                  Contact Us<i className="icon-next" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToActionSco;